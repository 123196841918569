<template>
  <div class="app-container">
    <el-card shadow="never">
      <div class="-mb-16px">
        <el-form
          ref="searchRefs"
          :model="params"
          size="small"
          label-width="45px"
          inline
        >
          <el-form-item label="账号" prop="account">
            <el-input
              v-model="params.account"
              placeholder="请输入账号"
            ></el-input>
          </el-form-item>
          <el-form-item label="名称" prop="name">
            <el-input v-model="params.name" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="params.status" placeholder="请选择状态">
              <el-option label="全部" value=""></el-option>
              <el-option label="正常" value="1"></el-option>
              <el-option label="停用" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="ml-6px">
            <el-button type="primary" @click="getList(1)">查询</el-button>
            <el-button @click="onReset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-card class="mt-16px" v-loading="pager.loading" shadow="never">
      <div>
        <el-button
          type="primary"
          size="small"
          @click="$router.push('/perm/administrator_detail')"
        >
          新增管理
        </el-button>
      </div>
      <div class="mt-16px">
        <el-table :data="pager.list">
          <el-table-column
            label="管理ID"
            prop="id"
            width="80"
          ></el-table-column>
          <el-table-column label="管理账号" prop="account"></el-table-column>
          <el-table-column label="管理名称" prop="name"></el-table-column>
          <el-table-column label="创建时间" prop="login_time" width="160">
            <template v-slot="scope">
              <span>{{
                formatDate(scope.row.create_time, "yyyy-mm-dd hh:MM:ss")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="最近登录时间" prop="login_time" width="160">
            <template v-slot="scope">
              <span>{{
                formatDate(scope.row.login_time, "yyyy-mm-dd hh:MM:ss")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="最近登录IP"
            prop="login_ip"
            width="140"
          ></el-table-column>
          <el-table-column label="状态" width="80">
            <template v-slot="scope">
              <el-tag size="small" v-if="scope.row.status === 1">正常</el-tag>
              <el-tag size="small" type="danger" v-else>停用</el-tag>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <div class="flex items-center">
                <el-button
                  type="text"
                  @click="
                    $router.push({
                      path: '/perm/administrator_detail',
                      query: { id: scope.row.id },
                    })
                  "
                >
                  编辑
                </el-button>
                <el-divider
                  v-if="scope.row.root !== 1"
                  direction="vertical"
                ></el-divider>
                <popup
                  v-if="scope.row.root !== 1"
                  :content="`确定删除：${scope.row.name}？请谨慎操作。`"
                  @confirm="onDelete(scope.row)"
                >
                  <el-button slot="trigger" type="text">删除</el-button>
                </popup>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-between mt-16px">
        <div class="flex"></div>
        <pagination
          v-model="pager"
          :pageSizes="[10, 20, 30]"
          @change="getList()"
        ></pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import Pagination from "@/components/pagination";
import Popup from "@/components/popup";
import { Pager } from "@/utils/pager";

import { apiListAdministrator, apiDeleteAdministrator } from "@/api/perm";
import { formatDate } from "@/utils/util";

export default {
  components: {
    Pagination,
    Popup,
  },
  data() {
    return {
      params: {
        account: "",
        name: "",
        status: "",
      },
      pager: new Pager(),
    };
  },
  created() {
    this.getList();
  },
  methods: {
    formatDate,
    onReset() {
      this.$refs.searchRefs.resetFields();
      this.getList(1);
    },
    getList(page) {
      page && (this.pager.page = page);
      this.pager.request({
        callback: apiListAdministrator,
        params: {
          ...this.params,
        },
      });
    },
    onDelete(row) {
      apiDeleteAdministrator({
        id: row.id,
      }).then(() => {
        this.$message({
          type: "success",
          message: "删除成功！",
        });
        this.getList();
      });
    },
  },
};
</script>
